export enum ApiMetricStatus {
  AVAILABLE = 'available',
  NEW = 'new',
  PLANNED = 'planned',
  SOON = 'soon',
};

export const ApiMetricStatusMap = {
  [ApiMetricStatus.AVAILABLE]: 'Available',
  [ApiMetricStatus.NEW]: 'New',
  [ApiMetricStatus.PLANNED]: 'Planned',
  [ApiMetricStatus.SOON]: 'Soon',
};