export enum MenuGroups {
  GAMIFICATION = 'gamification',
  USERS = 'users',
  TRENDING_PROJECTS = 'trending projects',
  CONTENT_CENTER = 'content center',
  EXCHANGES = 'exchanges',
  MOBILE_APP = 'mobile app',
  PERSONS = 'persons',
  DROP_HUNTING = 'drop hunting',
  ECOSYSTEMS = 'ecosystems',
  TMA = 'TMA',
  API = 'API',
}
