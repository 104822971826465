import { getEnvs } from '../env-lib';

export const FRONTEND_URL = getEnvs().REACT_APP_FRONTEND_URL || 'https://cryptorank.io';

export enum LocalStorageKeys {
  AUTH = 'auth',
  ROLE = 'role',
}

export const locales = [{ id: 'ru', name: 'Russian' }];

export enum Locale {
  EN = 'en',
  RU = 'ru',
}

export enum SortingDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum S3ImageDir {
  COINS = '/coins/',
  FUNDS = '/funds/',
  ICO_SCREENSHOTS = '/coins/ico-screenshots/',
  ICO_EXPERTS = '/ico-experts/',
  IDO_PLATFORMS = '/ido-platforms/',
  EXCHANGES = '/exchanges/',
  SRENNAB = '/srennab/',
  SNAPSHOTS = '/snapshots/',
  PERSONS = '/persons/',
  API_METRICS = '/api-metrics/',
}

export enum FormType {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum ErrorTypes {
  DUBLICATE_PRIORITY = 'UQ_4e3d644404c5908923bd3d77e6c',
}

export const imageInputAccept = 'image/jpeg, image/png, image/jpg, image/webp';
