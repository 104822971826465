import { format } from 'date-fns';
import { ReactNode, useState } from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  FunctionField,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  UrlField,
} from 'react-admin';
import { TMAQuizRecord } from './types';

const renderAnswers = (index: number) => (record: TMAQuizRecord): ReactNode => {
  const answer = record.answers?.[index];

  if (!answer) {
    return null;
  }

  if (answer.is_correct) {
    return `${answer.title} (правильный ответ)`;
  }

  return answer.title;
};

export const TMAQuizList = (props) => {
  return (
    <List perPage={50} {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label="Id" />
        <UrlField source="redirect_url" label="Cryptorank URL" />
        <TextField source="ask" label="Question" />
        <TextField source="configuration_id" label="Quiz config ID" />
        <FunctionField label="Answer 1" render={renderAnswers(0)} />
        <FunctionField label="Answer 2" render={renderAnswers(1)} />
        <FunctionField label="Answer 3" render={renderAnswers(2)} />
        <FunctionField label="Answer 4" render={renderAnswers(3)} />
        <FunctionField
          label="Created at"
          sortBy="created_at"
          render={(record) => format(new Date(record.created_at), 'dd.MM.yyyy, HH:mm:ss')}
        />
        <FunctionField
          label="Created at"
          sortBy="updated_at"
          render={(record) => format(new Date(record.created_at), 'dd.MM.yyyy, HH:mm:ss')}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const TMAQuizCreate = (props) => {
  const [isQuizIdEnabled, setQuizIdEnabled] = useState(false);
  const handleTaskTypeChange = (e) => {
    if (e) {
      setQuizIdEnabled(true);
    } else {
      setQuizIdEnabled(false);
    }
  };

  return (
    <Create {...props} redirect={(basePath) => basePath}>
      <SimpleForm>
        <TextInput source="ask" label="Question text" />
        <TextInput source="redirect_url" label="Cryptorank target URL" />
        <ReferenceInput source="configuration_id" reference="tma/quiz/configuration/" perPage={100}>
          <SelectInput onChange={handleTaskTypeChange} />
        </ReferenceInput>
        <ArrayInput source="answers" label="Answerslabel" disabled={isQuizIdEnabled}>
          <SimpleFormIterator inline>
            <TextInput source="title" label="Answer text" />
            <BooleanInput source="is_correct" label="Is correct answer" defaultChecked={false} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const TMAQuizEdit = (props) => {
  const [isQuizIdEnabled, setQuizIdEnabled] = useState(false);
  const handleTaskTypeChange = (e) => {
    if (e) {
      setQuizIdEnabled(true);
    } else {
      setQuizIdEnabled(false);
    }
  };
  return (
    <Edit {...props} redirect={(basePath) => basePath}>
      <SimpleForm>
        <TextInput source="ask" label="Question text" />
        <TextInput source="redirect_url" label="Cryptorank target URL" />
        <ReferenceInput source="configuration_id" reference="tma/quiz/configuration/">
          <SelectInput onChange={handleTaskTypeChange} />
        </ReferenceInput>
        {!isQuizIdEnabled && (
          <ArrayInput source="answers" label="Answerslabel" disabled={isQuizIdEnabled}>
            <SimpleFormIterator inline>
              <TextInput source="title" label="Answer text" />
              <BooleanInput source="is_correct" label="Is correct answer" defaultChecked={false} />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </SimpleForm>
    </Edit>
  );
};
